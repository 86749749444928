import React, { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { mediaQueries } from "../utils/mediaQueries";
import { rem } from "../utils/rem";

interface ContainerProps {
  className?: string;
  children: ReactNode;
  as?: "section" | "div" | "article" | "aside";
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, as }, ref) => (
    <StyledContainer className={className} ref={ref} as={as}>
      {children}
    </StyledContainer>
  ),
);

Container.displayName = "Container";

const StyledContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 ${rem(20)};
  box-sizing: border-box;

  ${mediaQueries.desktop} {
    padding: 0 ${rem(70)};
    max-width: ${rem(1440)};
  }
`;

export default Container;
