import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { rem } from "../../utils/rem";

import facebookIcon from "./images/facebook-icon.svg";
import twitterIcon from "./images/twitter-icon.svg";
import instagramIcon from "./images/instagram-icon.svg";
import linkedinIcon from "./images/linkedin-icon.svg";
import youtubeIcon from "./images/youtube-icon.svg";
import copyrightIcon from "./images/copyright-icon.svg";
import arrowUpRightIcon from "./images/arrow-up-right.svg";
import arrowDownIcon from "./images/arrow-down.svg";
import playIcon from "./images/play-icon.svg";
import arrowRightIcon from "./images/arrow-right.svg";

export enum Icons {
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  INSTAGRAM = "instagram",
  LINKEDIN = "linkedin",
  YOUTUBE = "youtube",
  COPYRIGHT = "copyright",
  ARROW_UP_RIGHT = "arrow-up-right",
  ARROW_DOWN = "arrow-down",
  PLAY_ICON = "play-icon",
  ARROW_RIGHT = "arrow-right",
}

interface IconProps {
  icon: Icons;
  className?: string;
  width?: number;
}

const Icon: FunctionComponent<IconProps> = ({
  icon,
  className,
  width = 16,
}) => {
  let iconSource: string | null;

  switch (icon) {
    case Icons.FACEBOOK:
      iconSource = facebookIcon;
      break;
    case Icons.TWITTER:
      iconSource = twitterIcon;
      break;
    case Icons.INSTAGRAM:
      iconSource = instagramIcon;
      break;
    case Icons.LINKEDIN:
      iconSource = linkedinIcon;
      break;
    case Icons.YOUTUBE:
      iconSource = youtubeIcon;
      break;
    case Icons.COPYRIGHT:
      iconSource = copyrightIcon;
      break;
    case Icons.ARROW_DOWN:
      iconSource = arrowDownIcon;
      break;
    case Icons.ARROW_UP_RIGHT:
      iconSource = arrowUpRightIcon;
      break;
    case Icons.PLAY_ICON:
      iconSource = playIcon;
      break;
    case Icons.ARROW_RIGHT:
      iconSource = arrowRightIcon;
      break;
    default:
      iconSource = null;
  }

  return iconSource ? (
    <StyledImage
      src={iconSource}
      alt={`icon-${icon}`}
      className={className}
      width={width}
    />
  ) : null;
};

const StyledImage = styled.img<{ width: number }>`
  width: ${({ width }) => rem(width)};
`;

export default Icon;
