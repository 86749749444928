import { createGlobalStyle } from "styled-components";

/**
 * @license
 * MyFonts Webfont Build ID 4011045, 2021-02-10T05:08:14-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: TradeGothicLT-BoldTwo by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/bold-no-2-63882/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1989, 1992, 2003 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Library GmbH, and
 *
 * Webfont: FF Mark Web Pro Book by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/mark/pro-book/
 * Copyright: 2013 published by FontShop International GmbH
 *
 * Webfont: TradeGothicLTPro-BdCn20Obl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/trade-gothic/pro-bold-condensed-20-oblique/
 * Copyright: Copyright 2014 Monotype GmbH. All rights reserved.
 *
 *
 *
 * © 2021 MyFonts Inc
 */

import TradeGothicBoldCondensedWoff from "../../fonts/trade-gothic-bold-condensed-20/font.woff";
import TradeGothicBoldCondensedWoff2 from "../../fonts/trade-gothic-bold-condensed-20/font.woff2";

import TradeGothicBoldCondensedObliqueWoff from "../../fonts/trade-gothic-bold-condensed-20-oblique/font.woff";
import TradeGothicBoldCondensedObliqueWoff2 from "../../fonts/trade-gothic-bold-condensed-20-oblique/font.woff2";

import FFMarkWoff from "../../fonts/ff-mark-web-pro-book/font.woff";
import FFMarkWoff2 from "../../fonts/ff-mark-web-pro-book/font.woff2";
import Colors from "../../tokens/Colors";

const GlobalStyles = createGlobalStyle<{ is404: boolean }>`
  @import url("//hello.myfonts.net/count/3d3425");

  @font-face {
  font-family: "Trade Gothic";
  font-weight: bold;
  src: url(${TradeGothicBoldCondensedWoff2}) format('woff2'), url(${TradeGothicBoldCondensedWoff}) format('woff');
}
@font-face {
  font-family: "Trade Gothic";
  font-weight: bold;
  font-style: oblique;
  src: url(${TradeGothicBoldCondensedObliqueWoff2}) format('woff2'), url(${TradeGothicBoldCondensedObliqueWoff}) format('woff');
}
@font-face {
  font-family: "FF Mark";
  src: url(${FFMarkWoff2}) format('woff2'), url(${FFMarkWoff}) format('woff');
}


  body {
    margin: 0;
    background: ${({ is404 }) => (is404 ? Colors.CONGRESS_BLUE : Colors.WHITE)}
  }
`;

export default GlobalStyles;
