import styled, { css } from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";

const Grid = styled.div(
  () => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${rem(20)};
    align-items: start;

    ${mediaQueries.desktop} {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: ${rem(20)};
    }
  `,
);

export default Grid;

interface ResponsiveColumns {
  mobile?: number | "auto";
  tablet?: number | "auto";
  desktop?: number | "auto";
}

export interface GridItemProps {
  span?: ResponsiveColumns;
  start?: ResponsiveColumns;
  order?: ResponsiveColumns;
}

export const GridItem = styled.div<GridItemProps>(
  ({ span = {}, start = {}, order = {} }) => css`
    grid-column-start: ${start.mobile || "auto"};
    grid-column-end: span ${span.mobile || 6};
    order: ${order.mobile || 0};

    ${mediaQueries.tablet} {
      grid-column-start: ${start.tablet || "auto"};
      grid-column-end: span ${span.tablet || 6};
      order: ${order.tablet || 0};
    }

    ${mediaQueries.desktop} {
      grid-column-start: ${start.desktop || "auto"};
      grid-column-end: span ${span.desktop || 12};
      order: ${order.desktop || 0};
    }
  `,
);
