import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import getUTMLink from "../../utils/getUTMLink";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Grid, { GridItem } from "../grid/Grid";
import Icon, { Icons } from "../icon/Icon";
import Title from "../title/Title";
import teamLiquidLogo from "./images/logo-liquid.svg";

const Footer: FunctionComponent = () => (
  <StyledFooter>
    <Container>
      <StyledGrid>
        <GridItem
          order={{
            desktop: 0,
          }}
          span={{
            mobile: 3,
            tablet: 3,
            desktop: 2,
          }}
        >
          <StyledTitle color={Colors.WHITE} size={Sizes.EXTRA_SMALL}>
            In proud partnership with
          </StyledTitle>
          <StyledAnchor
            href="https://www.teamliquid.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={teamLiquidLogo} alt="logo team liquid" />
          </StyledAnchor>
        </GridItem>

        <GridItem
          order={{
            desktop: 1,
          }}
          start={{
            desktop: 9,
          }}
          span={{
            desktop: 4,
          }}
        >
          <StyledTitle color={Colors.WHITE} size={Sizes.EXTRA_SMALL}>
            Menu
          </StyledTitle>
          <StyledMenuGrid>
            <StyledMenuItem
              href={getUTMLink("https://www.imc.com/eu/about", "about")}
              target="_blank"
              rel="noreferrer"
            >
              About us
            </StyledMenuItem>
            <StyledMenuItem
              href={getUTMLink(
                "https://www.imc.com/eu/foundation",
                "foundation",
              )}
              target="_blank"
              rel="noreferrer"
            >
              IMC giving
            </StyledMenuItem>
            <StyledMenuItem
              href={getUTMLink("https://www.imc.com/eu/discover", "discover")}
              target="_blank"
              rel="noreferrer"
            >
              Discover IMC
            </StyledMenuItem>
            <StyledMenuItem
              href={getUTMLink("https://www.imc.com/eu/contact", "contact")}
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </StyledMenuItem>
            <StyledMenuItem
              href={getUTMLink(
                "https://www.imc.com/eu/institutional-trading",
                "institutional-trading",
              )}
              target="_blank"
              rel="noreferrer"
            >
              Access our liquidity
            </StyledMenuItem>
            <StyledMenuItem
              href={getUTMLink("https://www.imc.com/eu/news", "news")}
              target="_blank"
              rel="noreferrer"
            >
              News
            </StyledMenuItem>
          </StyledMenuGrid>
        </GridItem>

        <StyledSocialGridItem
          order={{
            desktop: 3,
          }}
          span={{
            desktop: 4,
          }}
          start={{
            desktop: 9,
          }}
        >
          <StyledSocialIcon
            href="https://www.facebook.com/IMCTrading"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={Icons.FACEBOOK} />
          </StyledSocialIcon>
          <StyledSocialIcon
            href="https://twitter.com/IMCTrading"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={Icons.TWITTER} />
          </StyledSocialIcon>
          <StyledSocialIcon
            href="https://www.instagram.com/imctrading"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={Icons.INSTAGRAM} />
          </StyledSocialIcon>
          <StyledSocialIcon
            href="https://www.linkedin.com/company/imc-financial-markets"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={Icons.LINKEDIN} />
          </StyledSocialIcon>
          <StyledSocialIcon
            href="https://www.youtube.com/channel/UCw-VMZIu0j-_IDIAh74Cm_A"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon={Icons.YOUTUBE} />
          </StyledSocialIcon>
        </StyledSocialGridItem>

        <StyledFooterLinksGridItem
          order={{
            desktop: 2,
          }}
          span={{
            desktop: 5,
          }}
        >
          <StyledFooterLink
            href={getUTMLink(
              "https://www.imc.com/privacy-statement",
              "privacy",
            )}
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </StyledFooterLink>
          <StyledFooterLink
            href={getUTMLink("https://www.imc.com/disclaimer", "disclaimer")}
            target="_blank"
            rel="noreferrer"
          >
            Disclaimer and cookies
          </StyledFooterLink>
          <StyledCopyrightLabel>&copy; IMC 2021</StyledCopyrightLabel>
        </StyledFooterLinksGridItem>
      </StyledGrid>
    </Container>
  </StyledFooter>
);

const StyledFooterLink = styled.a`
  display: inline-block;
  color: ${Colors.WHITE};
  font-weight: bold;
  font-family: "Trade Gothic", Arial, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  font-size: ${rem(12)};
  margin: 0 ${rem(20)} 0 0;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledCopyrightLabel = styled.span`
  font-size: ${rem(12)};
  text-transform: uppercase;
  color: ${Colors.WHITE};
  font-family: "Trade Gothic", Arial, sans-serif;
  font-weight: bold;
  opacity: 0.4;
  display: block;
  margin: ${rem(16)} 0 0;

  ${mediaQueries.desktop} {
    margin: 0 ${rem(20)} 0 0;
    display: inline-block;
    order: -1;
  }
`;

const StyledSocialGridItem = styled(GridItem)`
  display: flex;
`;

const StyledSocialIcon = styled.a`
  margin-right: ${rem(15)};
  height: ${rem(30)};
  width: ${rem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

const StyledMenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${rem(20)};
`;

const StyledMenuItem = styled.a`
  display: block;
  color: ${Colors.WHITE};
  font-style: oblique;
  font-weight: bold;
  font-family: "Trade Gothic", Arial, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  height: ${rem(40)};
  line-height: ${rem(40)};
  white-space: nowrap;
  font-size: ${rem(14)};

  &:hover {
    text-decoration: underline;
  }

  ${mediaQueries.desktop} {
    font-size: ${rem(16)};
  }
`;

const StyledTitle = styled(Title)`
  opacity: 0.4;
`;

const StyledAnchor = styled.a`
  display: inline-block;
  line-height: 0;
  width: 100%;

  > img {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  row-gap: ${rem(40)};
  margin: ${rem(50)} 0;

  ${mediaQueries.desktop} {
    row-gap: ${rem(120)};
    margin: ${rem(80)} 0;
  }
`;

const StyledFooterLinksGridItem = styled(GridItem)`
  ${mediaQueries.desktop} {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
  }
`;

const StyledFooter = styled.footer`
  background: ${Colors.STRATOS};
  color: ${Colors.WHITE};
`;

export default Footer;
