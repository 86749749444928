import React, { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";

interface TitleProps {
  className?: string;
  children: ReactNode;
  size?: Sizes;
  as?: "h1" | "h2" | "h3" | "h4";
  color?: Colors;
}

const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  (
    {
      children,
      className,
      as,
      size = Sizes.MEDIUM as Sizes,
      color = Colors.WHITE,
    },
    ref,
  ) => (
    <StyledTitle
      className={className}
      ref={ref}
      size={size}
      as={as}
      color={color}
    >
      {children}
    </StyledTitle>
  ),
);

Title.displayName = "Title";

const StyledTitle = styled.h1<{ size: Sizes; color: Colors }>`
  font-family: "Trade Gothic", arial, sans-serif;
  line-height: 1;
  font-style: oblique;
  text-transform: uppercase;
  margin: 0;
  color: ${({ color }) => color};

  ${({ size }) =>
    size === Sizes.EXTRA_SMALL &&
    `font-size: ${rem(12)}; margin: 0 0 ${rem(
      12,
    )}; line-height: 1.2; font-style: normal; font-style: normal;`}

  ${({ size }) =>
    size === Sizes.SMALL &&
    `font-size: ${rem(14)}; margin: 0 0 ${rem(
      10,
    )}; line-height: 1.2; font-style: normal;`}

  ${({ size }) =>
    size === Sizes.MEDIUM &&
    `font-size: ${rem(32)}; margin: 0 0 ${rem(25)}; letter-spacing: -2px;`}

  ${({ size }) =>
    size === Sizes.LARGE && `font-size: ${rem(60)}; margin: 0 0 ${rem(28)};`}

  ${mediaQueries.desktop} {
    ${({ size }) =>
      size === Sizes.SMALL &&
      `font-size: ${rem(16)}; margin: 0 0 ${rem(14)}; line-height: 1;`}

    ${({ size }) =>
      size === Sizes.LARGE && `font-size: ${rem(220)}; margin: 0 0 ${rem(90)};`}
  }
`;

export default Title;
