const UTMParameters = {
  utm_source: "teamliquid",
  utm_campaign: "campaignsite",
  utm_medium: "referal",
};

const getUTMLink = (url: string, content: string): string => {
  const params = {
    ...UTMParameters,
    utm_content: content,
  };

  const parameterString = new URLSearchParams(params).toString();

  return `${url}?${parameterString}`;
};

export default getUTMLink;
