// Color names based on http://chir.ag/projects/name-that-color/

enum Colors {
  CONGRESS_BLUE = "#005194",
  BONDI_BLUE = "#00B5B1",
  STRATOS = "#000A3C",
  DOVE_GRAY = "#696969",
  BLACK = "#000000",
  WHITE = "#ffffff",
  SILVER = "#C4C4C4",
  GREEN_YELLOW = "#A9FB4E",
}

export default Colors;
