import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import CookieConsent from "../cookie-consent/CookieConsent";
import Footer from "../footer/Footer";
import GlobalStyles from "../globalStyles/GlobalStyles";
import Seo from "../seo/Seo";

import { LocationProvider } from "@reach/router";

interface AppProps {
  children: ReactNode;
  rootSelector?: string;
  pageContext: {
    layout?: string;
  };
}

const App: FunctionComponent<AppProps> = ({ children, pageContext }) => (
  <LocationProvider>
    <GlobalStyles is404={pageContext.layout === "404"} />
    <CookieConsent />
    <Seo />
    <StyledLayout>
      {children}
      <Footer />
    </StyledLayout>
  </LocationProvider>
);

const StyledLayout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default App;
