import { darken } from "polished";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../../tokens/Colors";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Icon, { Icons } from "../icon/Icon";

interface ButtonProps {
  icon?: Icons;
  className?: string;
  onClick?: () => void;
  href?: string;
}

const Button: FunctionComponent<ButtonProps> = ({
  className,
  children,
  icon = Icons.ARROW_RIGHT,
  onClick,
  href,
}) => (
  <StyledButtonWrapper className={className}>
    <StyledAnchor
      href={href}
      onClick={onClick}
      rel="noreferrer"
      target="_blank"
    >
      <StyledLabel>{children}</StyledLabel>
      <StyledIconWrapper>
        <StyledIcon icon={icon} />
      </StyledIconWrapper>
    </StyledAnchor>
  </StyledButtonWrapper>
);

const StyledLabel = styled.span`
  position: relative;
  color: ${Colors.CONGRESS_BLUE};
  line-height: 1.1875;
  transform: translateY(3px);
`;
const StyledIcon = styled(Icon)`
  width: ${rem(12)};
`;

const StyledAnchor = styled.a`
  color: ${Colors.WHITE};
  font-family: "Trade Gothic", Arial, sans-serif;
  font-style: oblique;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  height: ${rem(50)};
  padding: 0 ${rem(10)};
  font-size: ${rem(16)};

  &:before {
    content: "";
    width: calc(100% + ${rem(20)});
    height: 100%;
    position: absolute;
    top: 0;
    left: -${rem(10)};
    transition: background-color 0.2s ease-in-out;
    background: ${Colors.GREEN_YELLOW};

    clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
  }

  &:hover {
    &:before {
      background: ${darken(0.2, Colors.GREEN_YELLOW)};
    }
  }
`;

const StyledButtonWrapper = styled.span`
  display: flex;
  justify-content: center;

  ${mediaQueries.desktop} {
    justify-content: flex-start;
  }
`;

const StyledIconWrapper = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(10)};
  position: relative;
`;

export default Button;
