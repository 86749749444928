import React, { FunctionComponent, useCallback } from "react";
import ReactCookieConsent from "react-cookie-consent";
import Colors from "../../tokens/Colors";
import Button from "../button/Button";

const CookieConsent: FunctionComponent = () => {
  const onAccept = useCallback(() => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "consent",
      });
    }
  }, []);

  return (
    <ReactCookieConsent
      buttonText="I understand"
      style={{
        background: Colors.STRATOS,
        fontFamily: "'FF Mark', arial, sans-serif",
        fontSize: 12,
        padding: "10px 20px",
        boxSizing: "border-box",
      }}
      contentStyle={{
        margin: "10px 0",
      }}
      ButtonComponent={Button}
      onAccept={onAccept}
    >
      This Website uses Google Analytic cookies. These cookies help IMC to
      improve its website, by analyzing the usage. By continuing to use this
      Website the user consents to the use of these cookies.
    </ReactCookieConsent>
  );
};

export default CookieConsent;
